<template>
  <common-warpper title="业务体系" en="BUSINESS GROUP" :imgSrc="banner">
    <div class="system" v-if="list">
      <ul class="list">
        <li
          v-for="(item, i) in list"
          :key="i"
          @click="$router.push(`/newsDetail?id=${item.id}&name=体系`)"
        >
          <img class="image" :src="item.icon" />
          <div class="info">
            <div>
              <div class="title">
                <p>
                  {{ item.title }}
                </p>
              </div>
              <div class="text">
                {{ item.summary }}
              </div>
            </div>
            <span>{{ item.createTime }}</span>
          </div>
        </li>
      </ul>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
    </div>
  </common-warpper>
</template>
<script>
import CommonWarpper from "@/components/CommonWarpper.vue";
import banner from "@/assets/images/home/banner2.jpg";
import { pageByCode } from "@/api/index";

export default {
  name: "System",
  components: {
    CommonWarpper,
  },
  data() {
    return {
      banner,
      list: [],
      // 总条数
      total: 0,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        title: undefined,
        code: "yun_group",
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      pageByCode(this.queryParams).then((res) => {
        this.list = res.data && res.data.records;
        this.total = res.data && res.data.total;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss"; // global css
.system {
  .list {
    li {
      display: flex;
      cursor: pointer;
      padding-top: 30px;
      padding-bottom: 30px;
      padding-right: 15px;
      border-bottom: 1px solid #eee;
      &:hover {
        .image {
          transform: scale(1.1);
        }
      }
      .image {
        width: 196px;
        height: 130px;
        margin-right: 25px;
        object-fit: cover;
        border-radius: 2px;
        transition: 0.5s all;
      }
      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .title {
        display: flex;
        line-height: 30px;
        p {
          font-size: 16px;
          color: #000;
          flex: 1;
          @include clamp(1);
        }
      }
      .text {
        display: none;
        margin-top: 10px;
        line-height: 1.6;
        font-size: 14px;
        color: #666;
        @include clamp(3);
      }
      span {
        font-size: 12px;
        color: #999;
      }
    }
  }
}
</style>
